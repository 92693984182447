<ngx-loading-bar color="#f173ac" [includeSpinner]="false"></ngx-loading-bar>
<section class="cookiebar">
    <ins-cookie-settings-modal-nl/>
</section>

<header-component [useAngularRouter]="true"></header-component>

<main role="main">
    <ins-breadcrumb *ngIf="!((seoService.routeData$ | async)?.[hideBreadcrumb])"></ins-breadcrumb>
    <router-outlet></router-outlet>
    <wuc-slogan-footer>
        <ins-breadcrumb-bordered slot="breadcrumbs"></ins-breadcrumb-bordered>
    </wuc-slogan-footer>
</main>

<footer-component *ngIf="!((seoService.routeData$ | async)?.[hideFooter])" e2e="footer"></footer-component>
<router-outlet name="modal"></router-outlet>

<ins-chatbot-fixed-container></ins-chatbot-fixed-container>
