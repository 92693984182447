import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppConfigService, initializeAppFactory } from '@inshared/shared/core';
import { windowProvider } from '@inshared/shared/util';
import { WebsiteUiComponentsModule, WucSloganFooterModule, WucVideoClass } from '@inshared/website-ui-components';
import {
    ApiVersionEnum,
    OutAuthenticationModule,
    OutCoreModule,
    OutRelationModule,
    provideDatalayer,
} from 'outshared-lib';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NewRelicService } from './core/analytics/newrelic.service';
import { WebVitalsService } from './core/analytics/web-vitals.service';
import { phonenumberConfig } from './core/config/phonenumber-config';
import { pikadayConfig } from './core/config/pikaday-config';
import { CoreModule } from './core/core.module';
import { AppGlobalErrorhandler } from './core/error-handler';
import { LivechatModule } from './core/livechat/livechat.module';
import { InsCookieService } from './core/services';
import { FeatureDetectionService } from './core/services/feature-detection.service';
import { ChatbotFixedModule } from './shared/customer-service/chatbot';
import { VideoNoticeComponent, VideoNoticeModule } from './shared/ux/video-notice';
import { AppStoreModule } from './store/app-store.module';
import { CookieSettingsModalNLComponent } from '@inshared/shared/feature-cookie';

registerLocaleData(localeNl, 'nl');

// the Request object only lives on the server
export function getRequest(): any {
    return { headers: { cookie: document.cookie } };
}

@NgModule({
    imports: [
        BrowserModule.withServerTransition({appId: 'inshared-website'}),
        AppRoutingModule,
        AppStoreModule,
        CoreModule,
        ChatbotFixedModule,

        // OutShared Library
        OutCoreModule.forRoot(
            {
                production: environment.production,
                debug: false,
                apiUrl: '/api/v2',
                version: ApiVersionEnum.Classic,
            },
            {indexableDomains: ['www', 'test', 'insh-nl-frontend-website', 'acceptatie'], notIndexableDomains: []}
        ),
        OutAuthenticationModule,
        OutRelationModule,
        LivechatModule.forRoot({
            scriptSrc: 'https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js',
            dataAppId: 'c7a66c1c-15b9-4345-9470-23720c6f28c8',
            dataLcwVersion: 'prod',
            dataOrgId: '63f36d6f-398e-427f-8901-a5a126da634f',
            dataOrgUrl: 'https://unq63f36d6f398e427f8901a5a126da6-crm4.omnichannelengagementhub.com',
        }),
        WebsiteUiComponentsModule.forRoot({
            pikadayConfig: pikadayConfig,
            phonenumberConfig: phonenumberConfig,
            noVideoComponent: new WucVideoClass(VideoNoticeComponent, InsCookieService.cookieConsent$),
            /** @deprecated passwordConfig this is moved to libs */
            passwordConfig: {
                // only the properties that deviate from the default config 'inputPasswordDefaultConfig'
                passwordContainsText: 'Heeft uw wachtwoord:',
                amountOfLettersText: 'Ten minste # letters',
                amountOfNumbersText: 'Ten minste # cijfer',
                passwordLengthText: '#1 of meer tekens (en maximaal #2)',
                amountOfSpecialCharactersText: 'Maak uw wachtwoord sterker met # van deze tekens:',
                hintTextEmpty:
                    'Kies geen wachtwoord die u ook voor andere websites gebruikt of die gemakkelijk te ' + 'raden is.',
                hintTextOk: 'Goed bezig ☺',
                hintTextLetters: 'In uw wachtwoord ontbreekt nog een letter',
                hintTextNumbers: 'In uw wachtwoord ontbreekt nog een cijfer',
                hintTextSpecialCharacters: 'U kunt uw wachtwoord nog sterker maken met 1 van deze tekens: #',
                hintTextInvalidSpecialCharacters:
                    'Eén van de speciale tekens die u heeft opgegeven is niet ' +
                    'mogelijk. U kunt naast cijfers en letters de volgende tekens gebruiken: #',
            },
        }),
        VideoNoticeModule,
        WucSloganFooterModule,
        CookieSettingsModalNLComponent,
    ],
    declarations: [AppComponent],
    providers: [
        windowProvider,
        WebVitalsService,
        FeatureDetectionService,
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AppConfigService],
            multi: true,
        },
        // added here because new-relic not included in shared lib
        // TODO: add new-relic to shared lib
        {
            provide: APP_INITIALIZER,
            useFactory: (newRelicService: NewRelicService) => () => newRelicService.init(),
            deps: [NewRelicService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: AppGlobalErrorhandler,
        },
        provideDatalayer(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
