import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from '../index/redirect.component';
/* 3rd party */
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Driver, NgForageConfig } from 'ngforage';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { FooterModule } from './footer/footer.module';
import { httpInterceptorProviders } from './http-interceptors/index';
/* cyno services */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HeaderModule } from '@inshared/shared/feature-header';
import { easingLogic } from '@inshared/shared/util';
import { throwIfAlreadyLoaded } from './module-import-guard';

/**
 * In de core alleen maar services & componenten die voor de hele app beschikbaar moeten zijn
 * De core wordt altijd maar 1 keer ingeladen, dus alle services hier zijn singletons!
 */
@NgModule({
    imports: [
        NgxPageScrollCoreModule.forRoot({
            duration: 800,
            easingLogic,
        }),
        RouterModule,
    ],
    exports: [LoadingBarHttpClientModule, HeaderModule, BreadcrumbModule, FooterModule],
    declarations: [RedirectComponent],
    providers: [{ provide: LOCALE_ID, useValue: 'nl' }, Title, Meta, httpInterceptorProviders],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule, ngfConfig: NgForageConfig) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
        ngfConfig.configure({
            name: 'InSharedApp',
            driver: [Driver.INDEXED_DB, Driver.LOCAL_STORAGE],
        });
    }
}
